import { CustomerMode } from "@features/customer/state/store";
import { UsersIcon } from "@heroicons/react/outline";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CustomerTriggerIconDomId } from "./_layout/customer";

export const CustomerModeWidget = () => {
  const el = document.getElementById(CustomerTriggerIconDomId);
  const location = useLocation();
  const [customerMode, setCustomerMode] = useRecoilState(CustomerMode);

  return (
    <>
      {el &&
        /^\/products/.test(location.pathname) &&
        createPortal(
          <div
            data-position="right"
            data-tooltip={
              customerMode
                ? "Désactiver le mode client"
                : "Activer le mode client"
            }
            style={{ zIndex: 1000000 }}
            className={`fixed left-4 bottom-3 p-2 rounded-full bg-white shadow-lg cursor-pointer print:hidden hover:bg-blue-100 hover:dark:bg-sky-900 dark:bg-slate-800 dark:active:bg-sky-900  ${
              customerMode
                ? "text-amber-300 hover:text-amber-600 dark:text-amber-900 dark:hover:text-amber-800 hover:bg-amber-100 dark:border-amber-900 border-2 "
                : " text-blue-600 hover:text-blue-800 dark:text-sky-900 dark:border-sky-900"
            }`}
            onClick={() => setCustomerMode(!customerMode)}
          >
            <UsersIcon className="h-8 w-8" />
          </div>,
          el
        )}
    </>
  );
};
