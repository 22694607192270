// hooks/useCheckVersion.ts
import { useEffect, useState } from "react";

export const useCheckVersion = () => {
  const [isUpdated, setIsUpdated] = useState(true);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch("/version.json", { cache: "no-store" });
        const { version } = await response.json();
        const storedVersion = localStorage.getItem("app_version");

        if (storedVersion !== version) {
          setIsUpdated(false); // Indique qu'une mise à jour est disponible
          localStorage.setItem("app_version", version);
        } else {
          setIsUpdated(true);
        }
      } catch (error) {
        console.error("Erreur lors de la vérification de la version :", error);
      }
    };

    checkVersion();
    // Optionnel : vérifier périodiquement toutes les X minutes
    const intervalId = setInterval(checkVersion, 5000 * 60 * 1); // 5 minutes
    return () => clearInterval(intervalId);
  }, []);

  return isUpdated;
};
