import { fetchServer } from "@features/utils/fetch-server";
import { Client, Order, OrderItem } from "../type";
import toast from "react-hot-toast";

export class CustomerAPIClient {
  static searchCustomer = async (search: any, codeCanalVente: string) => {
    const data = await fetchServer(
      `/customer/search?codeCanalVente=${codeCanalVente}`,
      {
        method: "POST",
        body: JSON.stringify({
          orderBy: "",
          orderDir: "",
          pageNumber: 1,
          pageSize: 8,
          query: search.query,
        }),
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result["items"] as Client[];
  };

  static createCustomer = async (infos: Client) => {
    //Temp patch --> 'til the backend updates
    const toSend = {
      nom: infos.nomCli,
      prenom: infos.prnomCli,
      mail: infos.addrMail,
      mobile: infos.mobil,
    };
    const data = await fetchServer("/customer/cash", {
      method: "POST",
      body: JSON.stringify(toSend),
    });
    const result = await data.json();
    if (data.status !== 200) {
      toast.error(result.detail.replace(/ \([^)]*\)/, ""));
      throw new Error(result.title);
    }
    return result as Client;
  };

  static getCustomerStatus = async (clientId: string) => {
    const data = await fetchServer(`/customer/cash/${clientId}`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static enhanceOrder = async (orderInfos: any) => {
    const data = await fetchServer("/order/cash/enhance", {
      method: "POST",
      body: JSON.stringify(orderInfos),
    });
    const result = await data.json();
    if (data.status !== 200) {
      toast.error(result.detail);
      throw new Error(result.title);
    }
    return result as Order;
  };

  static createOrder = async (orderInfos: any) => {
    const data = await fetchServer("/order/cash", {
      method: "POST",
      body: JSON.stringify(orderInfos as Order),
    });
    const result = await data.json();
    if (data.status !== 200) {
      toast.error(result.detail);
      throw new Error(result.title);
    }
    return result as Order;
  };

  static verifOrderLineMaj = async (orderID: string, item: OrderItem) => {
    console.log(item);
    const data = await fetchServer("/order/line/verif", {
      method: "POST",
      body: JSON.stringify({
        numCde: orderID,
        numLigne: item.numLigne,
        numLigneDisp: item.numLigneDisp,
        statu: item.statu,
        qte: item.qte,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      toast.error(result.detail);
      throw new Error(result.title);
    }
    return result as { response: string; msg: string };
  };
  static orderLineMaj = async (orderID: string, item: OrderItem) => {
    const data = await fetchServer("/order/line", {
      method: "PATCH",
      body: JSON.stringify({
        numCde: orderID,
        numLigne: item.numLigne,
        numLigneDisp: item.numLigneDisp,
        statu: item.statu,
        qte: item.qte,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      toast.error(result.detail);
      throw new Error(result.title);
    }
    return true;
  };

  static getUserCommandHistory = async (
    clientID: string,
    pageNumber: number = 2,
    pageSize: number = 20,
    mail: string = "",
    phone: string = ""
  ) => {
    const data = await fetchServer(`/customer/${clientID}/orders`, {
      method: "POST",
      body: JSON.stringify({
        orderBy: "",
        orderDir: "",
        pageNumber: pageNumber,
        pageSize: pageSize,
        mail: mail,
        mobile: phone,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return {
      pagination: {
        pageNumber: result.pageNumber,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        totalRows: result.totalRows,
        rowsReturned: result.rowsReturned,
      },
      items: result.items as Order[],
    };
  };

  static getClientSuggestion = async (
    search: string,
    codeCanalVente: string
  ) => {
    const data = await fetchServer(
      `/customer/suggestcli?term=${search}&codeCanalVente=${codeCanalVente}`
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Client[];
  };

  static getOrder = async (orderID: string) => {
    const data = await fetchServer(`/order/${orderID}`);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Order;
  };

  static getOrderItems = async (orderID: string) => {
    const data = await fetchServer(`/order/${orderID}/lines`, {
      method: "POST",
      body: JSON.stringify({
        orderBy: "",
        orderDir: "",
        pageNumber: 1,
        pageSize: 1000,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.tile);
    }

    return result.items as OrderItem[];
  };

  static refreshIndex = async () => {
    const data = await fetchServer(`/api/search/bulkindexcli`, {
      method: "POST",
      body: JSON.stringify({}),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.tile);
    }
  };

  static sendEmail = async (
    subject: string,
    html: string,
    to: string,
    attachment: { filename: string; base64: string }[]
  ) => {
    const data = await fetchServer("/sendmail", {
      method: "POST",
      body: JSON.stringify({
        subject: subject,
        html: html,
        to: [to],
        attachment,
      }),
    });
    if (data.status > 299) {
      const result = await data.json();
      throw new Error(result.title);
    }
  };

  /* static sendEmail = async (
    subject: string,
    html: string,
    to: string,
    attachment: { filename: string; base64: string }[]
  ) => {
    const data = await fetch("https://api.infew.praxiel.fr/sendmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CodeLieu": AuthJWT.codeLieu || "",
        Authorization: `Bearer ${AuthJWT.token}`,
      },
      body: JSON.stringify({
        subject: subject,
        html: html,
        to: [to, "Cyyril.gardenat@gmail.com"],
        attachment: [attachment],
      }),
    });
    console.log(data);
    const result = await data.json();
    if (data.status > 299) {
      throw new Error(result.title);
    }
  }; */
}
