import Accordion from "@atoms/accordion";
import { Button } from "@atoms/button/button";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { Base, BaseBoldSmall, BaseSmall, Title } from "@atoms/text";
import {
  PackageCard,
  ReceptionCard,
  SupplierOrderCard,
  getLibel,
} from "@components/reception/reception-cards";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionUnit } from "@features/reception/types";
import { ROUTES } from "@features/routes";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Bl, SupplierOrder } from "@features/supplier/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { Grid } from "@molecules/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  InformationCircleIcon,
  PlusCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Column } from "@molecules/table/table";
import { toDateISO } from "@features/utils/format/dates";
import { BookRender } from "@atoms/book";
import { ReceptionInfoModalAtom } from "../receptions-modals/reception-info-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import toast from "react-hot-toast";
import { Input } from "@atoms/input/input-text";

export const SupplierPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const {
    loading,
    refreshAllOngoingReceipt,
    allOngoingReceipt,
    changeSelectedReception,
    getHeaderReceipt,
    getReceipt,
    deleteReceipt,
  } = useReception();
  const { getFournBl, getFournBlPackages, getFournOrders } = useSuppliers();
  const { current: shopCodeLieu, functionsAuthorization } = useShopLocations();
  const [allBl, setAllBl] = useState<Bl[]>([]);
  const [hcdeEnabled, setHcdeEnabled] = useState(true);
  const [allCommand, setAllCommand] = useState<SupplierOrder[]>([]);
  const [blFilter, setBlFilter] = useState("");
  const [packageFilters, setPackageFilters] = useState<any>({});
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-global-grid-mode",
    {
      activated: true,
    }
  );
  const navigate = useNavigate();
  const { codeFourn } = useParams<{
    codeFourn: string;
    raisSocial: string;
  }>();

  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);

  useEffect(() => {
    setHcdeEnabled(
      !Object.keys(functionsAuthorization)
        .filter((k) => k.includes("RECEP"))
        .map((k) => functionsAuthorization[k].insert)
        .some((v) => v === false)
    );
  }, [functionsAuthorization]);

  useControlledEffect(() => {
    const loadTab = async () => {
      setAllBl(await getFournBl(codeFourn, "", false, false));
      const resCde = await getFournOrders({
        codeLieuLivr: shopCodeLieu?.codeLieu,
        codeFourn: codeFourn,
        trait: false,
        clot: false,
        recepValid: false,
      });
      setAllCommand(resCde.items);
    };
    refreshAllOngoingReceipt(undefined, false, {
      codeFourn: codeFourn || "",
      trait: false,
    });
    loadTab();
  }, [codeFourn]);

  useControlledEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - ${codeFourn}`;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const ongoingRecepColumns = [
    {
      title: "Fournisseur",
      render: (el) => (
        <div className="flex gap-2">
          <div className="flex flex-col justify-center items-end w-6 h-6">
            <BookRender src="" productType={el.modeGest} />
          </div>
          <Base>{el.codeFourn}</Base>
        </div>
      ),
    },

    {
      title: "Num. BL",
      render: (el) => el.numBL,
    },

    {
      title: "Num. reception",
      render: (el) => el.numRecep,
    },

    {
      title: "Type reception",
      render: (el) => getLibel(el),
    },
    {
      title: "Nombre de colis",
      render: (el) => (
        <Base className="lg:w-28 md:text-center lg:text-right">
          {el.nbrColis}
        </Base>
      ),
    },

    {
      orderable: true,
      title: "Date",
      render: (el) => toDateISO(el.dateRecep),
    },

    {
      title: "Actions",
      render: (el) => (
        <div className="w-full flex justify-center gap-1">
          <Button
            size="sm"
            onClick={(event) => {
              changeSelectedReception(el);
              setReceptionInfoModal(true);
              event.stopPropagation();
            }}
            icon={({ className }) => (
              <InformationCircleIcon className={className} />
            )}
          />
          <Button
            size="sm"
            theme="danger"
            icon={({ className }) => <XIcon className={className} />}
            onClick={(event) => {
              event.stopPropagation();
              changeSelectedReception(el);
              navigate(
                ROUTES.OngoingReception.replace(
                  /:numRecep/,
                  el.numRecep
                ).replace(/:clotStep/, "0")
              );
              setConfirmModal({
                theme: "danger",
                icon: () => (
                  <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                ),
                open: true,
                title: "Supprimer la réception",
                message: "Voulez vous supprimer cette réception",
                onAccept: async () => {
                  if (el.qteRecu > 0)
                    toast.error(
                      "Cette réception ne peut être supprimée car une ou plusieurs ligne(s) ont déja été réceptionnée(s)"
                    );
                  else {
                    await deleteReceipt(el);
                  }
                },
              });
            }}
          />
        </div>
      ),
    },
  ] as Column<any>[];

  const supplierOrderColumns = [
    {
      title: "Num. commande",
      render: (el) => <Base>{el.numCde}</Base>,
    },
    {
      title: "Date",
      render: (el) => (
        <Base>{new Date(el.dateCreat).toISOString().split("T")[0]}</Base>
      ),
    },
    {
      title: "Nb réf",
      render: (el) => <Base>{el.nbRef}</Base>,
    },
    {
      title: "Nb articles",
      render: (el) => <Base>{el.nbArticle}</Base>,
    },
  ] as Column<any>[];

  const chooseCard = (tabIndex: number, item: any) => {
    switch (tabIndex) {
      case 0:
        return <ReceptionCard item={item as ReceptionUnit} />;
      case 2:
        return <SupplierOrderCard item={item as SupplierOrder} />;
      default:
        return <ReceptionCard item={item} />;
    }
  };

  const chooseData = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return allOngoingReceipt.items;
      case 2:
        return allCommand;
      default:
        return [];
    }
  };
  return (
    <Page loading={loading}>
      <Title>{codeFourn}</Title>
      <div className="flex flex-col-reverse md:flex-row gap-4 mt-6 justify-between ">
        <div className="w-full md:w-1/2 flex flex-col mt-6 justify-end">
          <Tabs
            tabs={[
              {
                value: 0,
                label: `Réceptions en cours (${allOngoingReceipt.pagination.totalRows})`,
              },
              { value: 1, label: `Bons de livraisons (${allBl.length})` },
              {
                value: 2,
                label: `Commandes non clôturées (${allCommand.length})`,
              },
              //{ value: 3, label: "Attendus (3)" },
            ]}
            value={tabIndex}
            onChange={async (v) => {
              setTabIndex(v as number);
              if (v === 1)
                setAllBl(await getFournBl(codeFourn, "", false, false));
              if (v === 2) {
                const resCde = await getFournOrders({
                  codeLieuLivr: shopCodeLieu?.codeLieu,
                  codeFourn: codeFourn,
                  trait: false,
                  clot: false,
                  recepValid: false,
                });
                setAllCommand(resCde.items);
              }
            }}
          />{" "}
        </div>

        <div className="flex gap-2 mt-6 justify-end">
          <Frame className="flex flex-col gap-2 justify-between">
            <BaseSmall className="text-center">
              Les commandes du fournisseur seront ignorées.
            </BaseSmall>
            <Button
              authorized={hcdeEnabled}
              onClick={async () => {
                const newReceipt = await getHeaderReceipt({
                  codeLieu: shopCodeLieu?.codeLieu || "",
                  orig: "HCDE",
                  codeFourn: codeFourn || "",
                  numBL: "",
                  numCde: "",
                  numColis: "",
                });
                if (newReceipt) {
                  changeSelectedReception({
                    ...newReceipt,
                    orig: "HCDE",
                  });
                  navigate(ROUTES.ReceptionHeaderPage);
                }
              }}
              size="sm"
            >
              Créer réception hors commande
            </Button>
          </Frame>
        </div>
      </div>
      <div className="h-full w-full flex flex-col gap-2 my-12">
        {tabIndex === 1 && (
          <div className="flex gap-2">
            <BaseBoldSmall className="flex flex-col justify-center">
              Filtrer par numéro de BL
            </BaseBoldSmall>
            <Input
              className="max-w-96"
              size="sm"
              value={blFilter}
              onChange={(e) => {
                setBlFilter(e.target.value);
              }}
              placeholder="ex: 23305727..."
            />
          </div>
        )}
        <div className="mb-8 pb-8">
          {tabIndex !== 1 ? (
            <TableGridSwitch
              name="tableFournRecepPage"
              key={tabIndex}
              gridMode={gridMode.activated}
              onChangeMode={(v) => {
                setGridMode({ activated: v });
              }}
              gridClassName={
                "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
              }
              className="grow"
              data={chooseData(tabIndex) as any[]}
              onClick={async (e) => {
                if (e.numRecep && e.numRecep.trim() !== "") {
                  const resReceipt = await getReceipt(e);
                  if (!resReceipt.trait) {
                    if (resReceipt.recepOuver) {
                      setConfirmModal({
                        theme: "danger",
                        icon: () => (
                          <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                        ),
                        open: true,
                        title: "Réception en cours",
                        message:
                          "Cette réception est déjà en cours de traitement par: INFER. Souhaitez-vous continuer ?",
                        onAccept: async () => {
                          changeSelectedReception(resReceipt);
                          navigate(
                            ROUTES.OngoingReception.replace(
                              /:numRecep/,
                              resReceipt.numRecep
                            ).replace(/:clotStep/, "0")
                          );
                        },
                      });
                    } else {
                      changeSelectedReception(resReceipt);
                      navigate(
                        ROUTES.OngoingReception.replace(
                          /:numRecep/,
                          resReceipt.numRecep
                        ).replace(/:clotStep/, "0")
                      );
                    }
                  }
                } else if (tabIndex === 2) {
                  const newReceipt = await getHeaderReceipt({
                    codeLieu: shopCodeLieu?.codeLieu || "",
                    orig: "CDE",
                    codeFourn: e.codeFourn,
                    numBL: e.numBL,
                    numColis: "",
                    numCde: e.numCde,
                  });
                  if (newReceipt) {
                    changeSelectedReception(newReceipt);
                    navigate(ROUTES.ReceptionHeaderPage);
                  }
                }
              }}
              renderGrid={(el) => chooseCard(tabIndex, el)}
              tableColumns={
                tabIndex === 0 ? ongoingRecepColumns : supplierOrderColumns
              }
            />
          ) : (
            <Accordion
              panels={allBl
                .filter((b) =>
                  b.numBL.toLowerCase().includes(blFilter.toLocaleLowerCase())
                )
                .map((bl, index) => {
                  return {
                    value: bl.numBL,
                    onOpen: async (p) => {
                      const allPackages = await getFournBlPackages(bl);
                      const tempBlList = [...allBl];
                      tempBlList[index].listSupplierPackage = allPackages;
                      setAllBl(tempBlList);
                    },
                    label: `BL ${bl.numBL} - ${bl.nbrColis} colis - ${
                      new Date(bl.dateCreat).toISOString().split("T")[0]
                    } `,
                    node: (
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col items-start gap-2">
                          <Button
                            size="sm"
                            icon={(p) => <PlusCircleIcon {...p} />}
                            onClick={async (e) => {
                              const newReceipt = await getHeaderReceipt({
                                codeLieu: shopCodeLieu?.codeLieu || "",
                                orig: "AE",
                                codeFourn: bl.codeFourn,
                                numBL: bl.numBL,
                                numColis: "",
                                numCde: "",
                              });
                              if (newReceipt) {
                                changeSelectedReception({
                                  ...newReceipt,
                                  orig: "COLIS",
                                });
                                navigate(ROUTES.ReceptionHeaderPage);
                              }
                            }}
                          >
                            Créer une réception sur le BL complet
                          </Button>
                          <div className="flex gap-2 w-full my-2">
                            <BaseBoldSmall className="flex flex-col justify-center">
                              Filtrer par numéro de colis
                            </BaseBoldSmall>
                            <Input
                              className="max-w-64"
                              size="sm"
                              value={
                                packageFilters[bl.numBL]
                                  ? packageFilters[bl.numBL]
                                  : ""
                              }
                              onChange={(e) =>
                                setPackageFilters({
                                  ...packageFilters,
                                  [bl.numBL]: e.target.value,
                                })
                              }
                              placeholder="ex: 23302673"
                            />
                          </div>
                        </div>
                        <Grid
                          name="blPartFournRecepPage"
                          className="grow"
                          data={
                            packageFilters[bl.numBL]
                              ? bl.listSupplierPackage.filter((c) =>
                                  c.idColis
                                    .toLocaleLowerCase()
                                    .includes(
                                      packageFilters[
                                        bl.numBL
                                      ].toLocaleLowerCase()
                                    )
                                )
                              : allBl[index].listSupplierPackage
                              ? allBl[index].listSupplierPackage
                              : []
                          }
                          render={(el) => <PackageCard item={el} />}
                          onClick={async (colis) => {
                            if (!colis.recepOuver && !colis.recepTerm) {
                              const newReceipt = await getHeaderReceipt({
                                codeLieu: shopCodeLieu?.codeLieu || "",
                                orig: "COLIS",
                                codeFourn: colis.codeFourn,
                                numBL: colis.numBL,
                                numColis: colis.numColis,
                                numCde: "",
                              });

                              if (newReceipt) {
                                changeSelectedReception(newReceipt);
                                navigate(ROUTES.ReceptionHeaderPage);
                              }
                            } else {
                              if (colis.recepOuver)
                                toast.error(
                                  "Une réception est déjà en cours pour ce colis."
                                );
                              if (colis.recepTerm)
                                toast.error(
                                  "Un réception a déjà été crée et clôturée pour ce colis."
                                );
                            }
                          }}
                          emptyTabText="Aucun colis pour ce BL"
                        />
                      </div>
                    ),
                  };
                })}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
