import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

export const CommonConfirmModalAtom = atom<{
  open: boolean;
  title?: string;
  message?: string;
  icon?: any;
  theme?: "success" | "danger" | "warning" | "gray";
  confirmBtnText?: string;
  cancelBtnText?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}>({
  key: "ConfirmModalAtom",
  default: {
    open: false,
  },
});

export const ConfirmCommonModal = () => {
  const [modal, setModal] = useRecoilState(CommonConfirmModalAtom);

  const handleAccept = () => {
    if (modal.onAccept) {
      modal.onAccept();
    }
    setModal({ ...modal, open: false });
  };

  const handleCancel = () => {
    if (modal.onCancel) {
      modal.onCancel();
    }
    setModal({ ...modal, open: false });
  };

  const handleClose = () => {
    if (modal.onClose) {
      modal.onClose();
    }
    setModal({ ...modal, open: false });
  };
  return (
    <ConfirmModal
      open={modal.open}
      title={modal.title}
      theme={modal.theme}
      icon={modal.icon}
      message={modal.message}
      confirmBtnText={modal.confirmBtnText}
      cancelBtnText={modal.cancelBtnText}
      onAccept={handleAccept}
      onCancel={handleCancel}
      onClose={handleClose}
    />
  );
};

export const ConfirmModal = (props: {
  open: boolean;
  title?: string;
  theme?: "success" | "danger" | "warning" | "gray";
  message?: string;
  icon?: any;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onCancel && props.onCancel();
        props.onClose && props.onClose();
      }}
    >
      <ModalContent
        title={props.title || "Confirmer l'action ?"}
        icon={props.icon}
        text={props.message || "Cliquez sur confirmer pour continuer."}
        buttons={
          <>
            <Button
              theme="primary"
              disabled={loading}
              onClick={() => {
                setTimeout(() => {
                  try {
                    setLoading(true);
                    props.onAccept && props.onAccept();
                    setLoading(false);
                  } catch {
                    setLoading(false);
                  }
                }, 500);
              }}
              className="mr-4 my-2"
              shortcut={["enter"]}
            >
              {props.confirmBtnText || "Confirmer"}
            </Button>
            <Button
              disabled={loading}
              onClick={(e) => {
                try {
                  setLoading(true);
                  props.onCancel && props.onCancel();
                  setLoading(false);
                  props.onClose && props.onClose();
                } catch {
                  setLoading(false);
                  props.onClose && props.onClose();
                }
                props.onClose && props.onClose();
              }}
              theme="default"
              className={"mr-4 my-2 shadow-none"}
              shortcut={["esc"]}
            >
              {props.cancelBtnText || "Annuler"}
            </Button>
          </>
        }
      />
    </Modal>
  );
};
