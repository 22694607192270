import { LoadingState } from "@features/utils/store/loading-state-atom";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { CustomerAPIClient } from "../api-client/api-client";
import { Order, OrderItem } from "../type";
import { useGlobalEffect } from "@features/utils/hooks/use-global-effect";
import { useRecoilState } from "recoil";
import { ProductAtom } from "./store";
import { useParams } from "react-router-dom";

export const useOrder = (orderID: string) => {
  const [command, setCommand] = useRecoilState(ProductAtom(orderID));
  const { ts } = useParams<{ ts: string }>();
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useOrder" + orderID, false])
  );

  const createOrder = async (orderInfos: any) => {
    setLoading(true);
    const res = await CustomerAPIClient.createOrder(orderInfos);
    setLoading(false);
    return res;
  };
  const enhanceOrder = async (orderInfos: Order) => {
    return await CustomerAPIClient.enhanceOrder(orderInfos);
  };

  const verifOrderLineMaj = async (orderID: string, item: OrderItem) => {
    return await CustomerAPIClient.verifOrderLineMaj(orderID, item);
  };

  const orderLineMaj = async (orderID: string, item: OrderItem) => {
    return await CustomerAPIClient.orderLineMaj(orderID, item);
  };

  const getOrderItems = useCallback(async () => {
    return await CustomerAPIClient.getOrderItems(orderID);
  }, [orderID]);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const result = await CustomerAPIClient.getOrder(orderID);
      const commandItems = await getOrderItems();
      setCommand({ ...result, items: commandItems });
    } catch (err) {
      toast.error("Commande non trouvé.");
      setLoading(false);
    }
    setLoading(false);
  }, [setLoading, setCommand, orderID, getOrderItems]);

  useGlobalEffect(
    "useOrder+" + ts,
    async () => {
      if (orderID !== "") {
        await refresh();
      }
    },
    []
  );

  return {
    command,
    loading,
    enhanceOrder,
    createOrder,
    refresh,
    verifOrderLineMaj,
    orderLineMaj,
  };
};
