import Link from "@atoms/link";
import { BaseBold, InfoSmall } from "@atoms/text";
//import { ArrowRightIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";

export default function ModuleBox(props: {
  name?: string;
  subtTitle?: string;
  route?: string;
  logo: ReactNode;
  color?: string;
  disabled?: boolean;
}) {
  // const isDarkMode =
  //   window.matchMedia &&
  //   window.matchMedia("(prefers-color-scheme: dark)").matches;

  return (
    <Link
      to={props.route}
      className={`relative flex flex-col group w-full ${
        !props.disabled ? "cursor-pointer" : "cursor-not-allowed"
      }`}
      disabled={props.disabled}
    >
      <div className="relative shadow-lg text-center flex p-4 items-center w-full sm:w-64 h-30 group-hover:opacity-75 text-white rounded-lg flex flex-col border border-sm hover:border-blue-500 hover:border-2">
        {props.logo}
        <div className="flex items-center justify-center shrink-0 mt-3">
          <BaseBold>{props.name}</BaseBold>

          {/* <ArrowRightIcon className="w-4 h-4 text-neutral-900 text-center ml-3" /> */}
        </div>
        <InfoSmall className="!text-gray-400 text-center w-full whitespace-nowrap overflow-hidden text-ellipsis">
          {props.subtTitle}
        </InfoSmall>
      </div>
    </Link>
  );
}
