import { atom } from "recoil";
import { AuthType, ShopLocation } from "../types";

export const AuthState = atom<AuthType>({
  key: "AuthState",
  default: (() => {
    try {
      return {
        isLoggedIn: false,
        authorization: JSON.parse(
          localStorage.getItem("user.access_token") || "null"
        ),
        authorizationRefresh: JSON.parse(
          localStorage.getItem("user.refresh_token") || "null"
        ),
        user: null,
      };
    } catch (e) {
      return {
        isLoggedIn: false,
        authorization: null,
        authorizationRefresh: null,
        user: null,
      };
    }
  })(),
});

export const CurrentShopLocation = atom<ShopLocation | null>({
  key: "currentShopLocation",
  default:
    (JSON.parse(
      localStorage.getItem("user.location") || "null"
    ) as ShopLocation) || null,
});

export const ShopLocationList = atom<ShopLocation[]>({
  key: "userLocationList",
  default: [],
});

export const FunctionsAuthorizationList = atom<any>({
  key: "functionAuthorizationList",
  default: JSON.parse(
    localStorage.getItem("user.functions_authorization") || "null"
  ),

  //default: {},
});
